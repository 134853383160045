import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Grid } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { AccountTree, Newspaper, Psychology, QuestionMark, School } from '@mui/icons-material';
import { get, isNull } from 'lodash';

import { EMPTY_NUMBER } from 'src/utils/number';
import { theme } from 'src/styles/theme';

import { Section } from './section';
import { Overview } from './overview';
import { Experience } from './experience';
import { Projects } from './projects';
import { Publications } from './publications';
import { EducationSection } from './education';
import { ContactSection } from './contact';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  contact: {
    zIndex: 100,
    backgroundColor: '#fff',
  },
  contactFixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    animation: '$appear ease-out .5s',
    '-webkit-animation': '$appear ease-out .5s',
    '-moz-animation': '$appear ease-out .5s',
    '-o-animation': '$appear ease-out .5s',
    '-ms-animation': '$appear ease-out .5s',
    animationIterationCount: 1,
    transformOrigin: '50% 50%',
    animationFillMode: 'forwards', /* when the spec is finished */
  },
  '@keyframes appear': {
    '0%': {
        opacity: 0
    },
    '100%': {
      opacity: 1,
    },
  },
  '@-webkit-keyframes appear': {
    '0%': {
        opacity: 0
    },
    '100%': {
      opacity: 1,
    },
  },
  '@-moz-keyframes appear': {
    '0%': {
        opacity: 0
    },
    '100%': {
      opacity: 1,
    },
  },
  '@-o-keyframes appear': {
    '0%': {
        opacity: 0
    },
    '100%': {
      opacity: 1,
    },
  },
  '@-ms-keyframes appear': {
    '0%': {
        opacity: 0
    },
    '100%': {
      opacity: 1,
    },
  },
  overview: {},
  overviewFixed: {
    paddingTop: '170px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '169px',
    },
  },
  sectionContactIconGrid: {
    paddingBottom: '0 !important',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '20px !important',
    },
  },
})

type Props = {};

export const Content: React.FC<Props> = () => {
  const classes = useStyles();

  const contactRef = useRef<HTMLDivElement>(null);

  const [isFixedContact, setFixedContact] = useState<boolean>(false);

  useEffect(() => {
    const refOffset = get(contactRef, 'current.offsetTop', EMPTY_NUMBER);
    const fixContact = (_: Event) => {
      const isContactSectionFixed = !isNull(contactRef.current) && window.scrollY >= refOffset - 42;
      setFixedContact(isContactSectionFixed)
    }
    document.addEventListener('scroll', fixContact);
    return () => {
      document.removeEventListener('scroll', fixContact);
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Section
            title={'Contact'}
            className={{
              root: classnames([classes.contact, isFixedContact ? classes.contactFixed : void 0]),
              iconGrid: classes.sectionContactIconGrid,
            }}
          >
            <ContactSection ref={contactRef} />
          </Section>

          <Section
            title={'Overview'}
            icon={<QuestionMark />}
            className={{
              root: classnames([classes.overview, isFixedContact ? classes.overviewFixed : void 0])
            }}
          >
            <Overview />
          </Section>

          <Section
            title={'Experience'}
            icon={<Psychology />}
          >
            <Experience />
          </Section>

          <Section
            title={'Publications'}
            icon={<Newspaper />}
          >
            <Publications />
          </Section>

          <Section
            title={'Projects'}
            icon={<AccountTree />}
          >
            <Projects />
          </Section>

          <Section
            title={'Education'}
            icon={<School />}
          >
            <EducationSection />
          </Section>
        </Grid>
      </Grid>
    </div>
  );
}